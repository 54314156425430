.container {
    display: flex;
    .app_container {
        flex: 0 1 50%;
        .label {
        }
        .code_app_container {
            display: flex;
            justify-content: space-between;
           .code_item {
                font-size: 1.5em;
                flex: 0 0 2.25em;
                font-weight: 600;
                text-align: center;
                border-radius: 0.25em;
                padding: 0.375rem 0.75rem;
                &:not(:last-child) {
                    margin-right: 1em;
                }
                &.invalid {
                    border: 2px solid #ee2a1e !important;
                }
            }
        }
    }
}
.pin_container {
    position: relative;
    .btn_container {
        position: absolute;
        top: 0;
        right: 0;
        padding: 1.4em 0.5em 1.4em 0;
        z-index: 1;
        .coolDown {
            font-size: 0.8em;
        }
        .send {
            background-color: #fff;
            border: 0;
        }
    }
}
