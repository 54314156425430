@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '~bootstrap/scss/_utilities.scss';
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

.login_container {
    display: none;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background: #02131a;
    color: #ffffff;
    position: relative;
    &.open_login {
        display: flex;
    }
    @include media-breakpoint-up(md) {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
    @include media-breakpoint-up(lg) {
        padding-left: 8.188em;
        padding-right: 8.063em;
    }
    @include media-breakpoint-down(sm) {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
    .login_inner {
        position: relative;
        width: 100%;
        @include media-breakpoint-down(sm) {
            padding-top: 6.75em;
            > .row {
                margin: 0;
                > div {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .login_form_container {
            @include media-breakpoint-down(sm) {
                border-bottom: 0.063em solid #ffffff;
            }
            @include media-breakpoint-up(md) {
                border-right: 0.063em solid #ffffff;
            }
            h4 {
                font-size: 1.5em;
                font-weight: $font-weight-regular;
                margin-bottom: 0.583em;
            }
            p {
                font-size: 1em;
                font-weight: $font-weight-regular;
                margin-bottom: 2.625em;
            }
            a {
                color: #ffffff;
            }
            .form_error {
                color: red;
                font-weight: 600;
            }
            .social_form_error {
                color: red;
                font-weight: 600;
                margin-bottom: 1em;
            }
            form {
                .username label,
                .password label {
                    left: 2.5em;
                }
                .username input,
                .password input {
                    color: #d2d2d2;
                    &:focus-visible {
                        outline: none;
                    }
                    &:-internal-autofill-selected,
                    &:-internal-autofill-selected:hover,
                    &:-internal-autofill-selected:active,
                    &:-internal-autofill-selected:focus,
                    &:-internal-autofill-selected:focus-visible {
                        /*
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: #d2d2d2 !important;
                        */
                        transition-delay: 9999s;
                        transition-property: background-color, color;
                    }
                    &:not(:placeholder-shown) + label {
                        background: #02131a;
                        color: #d2d2d2;
                        padding: 0 1em;
                    }
                    &:focus {
                        background: transparent;
                        &:not([readonly]) {
                            border: 0.063em solid #cfcfcf;
                        }
                        & + label {
                            background: #02131a;
                            color: #d2d2d2;
                            padding: 0 1em;
                        }
                    }
                }
                .username input {
                    padding-left: 2.5em;
                }
                .password input {
                    padding-left: 1.1em;
                }
                input.form_control + label {
                    padding-left: 3.063em;
                    left: 0;
                }
                /*
                .username:after,
                .password:after {
                    @include icon-styles;
                    color: #d2d2d2;
                    position: absolute;
                    left: 1em;
                    top: 1.375em;
                }
                .username:after{
                    content: icon-char(user-fill);
                }
                .password:after {
                    content: icon-char(lock);
                }
                */
                .btn_container {
                    margin: 0;
                    .btn {
                        font-size: 0.875em;
                        font-weight: $font-weight-semi-bold;
                        padding: 1.143em 1.643em;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        margin-right: 0.714em;
                        justify-content: center;
                        color: #031921;
                        width: 100%;
                        @include media-breakpoint-up(md) {
                            width: 14em;
                        }
                    }
                }
            }
            .or_container {
                margin-top: 3em;
                margin-bottom: 3em;
                .or {
                    position: relative;
                    border: none;
                    height: 1px;
                    background: #999;

                    &::before {
                        content: attr(data-content);
                        display: inline-block;
                        background: #fff;
                        font-weight: bold;
                        font-size: 0.85rem;
                        color: #999;
                        border-radius: 30rem;
                        padding: 0.2rem 2rem;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .social_container {
                display: flex;

                @media screen and (max-width: 1199px) {
                    flex-direction: column;
                }
                @media screen and (max-width: 767px) {
                    margin-bottom: 1em;
                }
                .social_item {
                    flex: 1;
                    border: 0;
                    border-radius: 0.5em;
                    outline: none;
                    background-color: transparent;
                    // width: 32px;
                    // height: auto;
                    font-size: 0.875em;
                    font-weight: 600;
                    padding: 0.75em 0.75em;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    &:hover {
                        box-shadow: 0px 0px 5px 0px #fff;
                    }
                    &:not(:last-child) {
                        @media screen and (min-width: 1200px) {
                            margin-right: 3em;
                        }
                        @media screen and (max-width: 1199px) {
                            margin-bottom: 1em;
                        }
                    }
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                    &.github {
                        background-color: #444444;
                        fill: #ffffff;
                    }
                    &.google {
                        fill: #ffffff;
                        background-color: #dd4b39;
                    }
                    &.facebook {
                        fill: #ffffff;
                        background-color: #3b5998;
                    }
                    span {
                        margin-left: 0.75em;
                        border-left: 1px solid #ffffff;
                        padding-left: 0.75em;
                        line-height: 2em;
                    }
                }
            }

            .connector_status {
                display: flex;
                flex-direction: column;
                align-items: center;

                svg {
                    max-width: 100%;
                }
            }
        }
        .register_container {
            padding-top: 2.5em;
            @include media-breakpoint-up(md) {
                padding-top: 5.125em;
                text-align: center;
            }
            h3 {
                font-size: 2em;
                font-weight: $font-weight-regular;
                margin-bottom: 0.594em;
            }
            p {
                font-size: 1em;
                font-weight: $font-weight-regular;
                margin-bottom: 2em;
                @include media-breakpoint-up(md) {
                    margin-bottom: 4.25em;
                }
            }
            a.btn {
                width: 100%;
                text-transform: uppercase;
                padding: 1.143em 1.643em;
                font-size: 0.875em;
                font-weight: $font-weight-semi-bold;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 2.857em;
                }
                @include media-breakpoint-up(md) {
                    width: 19.571em;
                }
            }
        }
    }
    .sub_links {
        display: flex;
        @include media-breakpoint-down(sm) {
            margin: 0;
            padding: 0;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 2em;
        }
        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 5em;
            right: 8.063em;
        }
        li {
            display: flex;
            @include media-breakpoint-up(md) {
                margin-left: 2.813em;
            }
            a {
                font-size: 0.875em;
                color: #ffffff;
            }
        }
    }
}
